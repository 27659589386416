import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from 'file-saver';

import Constants from '../lib/Constants';
import * as ModelUtil from '../lib/ModelUtil';
import * as SkFormUtil from '../lib/SkFormUtil';
import * as DateFunctions from '../lib/DateFunctions';

import * as ClientApi from '../api/ClientApi';
import * as DataImportActionCreators from '../ducks/data_imports';
import * as CommonStateFunctions from '../ducks/common';
import TitleBar from '../components/TitleBar';
import DataTableModal from '../components/DataTableModal/DataTableModal';
import AssetImporterModal from '../components/AssetImporterModal/AssetImporterModal';
import UseImporterModal from '../apps/UseImporterModal/UseImporterModal';
import ProjectImporterModal from '../components/ProjectImporterModal/ProjectImporterModal';
import SpaceImporterModal from '../components/SpaceImporterModal/SpaceImporterModal';
import DataExceptionListing from '../components/DataExceptionElements/DataExceptionListing';


class ClientDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingImportData: [[]],
            showingImportName: '',
            showingImportDataModal: false,
            showingUseImporterModal: false,
            showingProjectImporterModal: false,
            showingAssetImporterModal: false,
            showingSpaceImporterModal: false
        };
    }

    componentDidMount() {
        const clientId = parseInt(this.props.match.params.client_id, 10);
        if (!this.props.client || this.props.client.client_id !== clientId) {
            this.props.loadClientAndDependencies(clientId);
        }

        if(!this.props.imports && !this.props.importsListIsLoading && !this.props.importsListHasErrored) {
            this.props.loadDataImportList(this.props.match.params.client_id);
        }
        this.setPageTitle();
    }

    componentDidUpdate(prevProps) {
        if(!this.props.imports && !this.props.importsListIsLoading && !this.props.importsListHasErrored) {
            this.props.loadDataImportList(this.props.match.params.client_id);
        }
        this.setPageTitle();
    }

    setPageTitle() {
        document.title = `${Constants.pageTitleBase} ${Constants.pageTitleSeparator} Dashboard`;
    }

    handleRollback = (importId, importName) => {
        let confirmed = window.confirm('Are you sure you want to roll back ' + importName + '?');
        if(confirmed) {
            this.props.rollbackImport(importId, {});
        }
    }

    handleUnrollback = (importId, importName) => {
        let confirmed = window.confirm('Are you sure you want to reverse the roll back of ' + importName + '?');
        if(confirmed) {
            this.props.unrollbackImport(importId, {});
        }
    }

    showImportDataModal = (importName, importData) => {
        this.setState({ showingImportDataModal: true, showingImportData: importData, showingImportName: importName });
    }

    onHideImportDataModal = () => {
        this.setState({ showingImportDataModal: false, showingImportData: [[]], showingImportName: '' });
    }

    showUseImporterModal = () => {
        this.setState({ showingUseImporterModal: true });
    }

    onHideUseImporterModal = () => {
        this.setState({ showingUseImporterModal: false });
    }

    showProjectImporterModal = () => {
        this.setState({ showingProjectImporterModal: true });
    }

    onHideProjectImporterModal = () => {
        this.setState({ showingProjectImporterModal: false });
    }

    showAssetImporterModal = () => {
        this.setState({ showingAssetImporterModal: true });
    }

    onHideAssetImporterModal = () => {
        this.setState({ showingAssetImporterModal: false });
    }

    showSpaceImporterModal = () => {
        this.setState({ showingSpaceImporterModal: true });
    }

    onHideSpaceImporterModal = () => {
        this.setState({ showingSpaceImporterModal: false });
    }

    skIssueDataForYear = async year => {
        return ((await ClientApi.issuesWithPuCalcs(this.props.client.client_id, year)) || [])
            .filter(i => !i.pu_calc.is_fully_defeased)
            .filter(i => i.dated_date >= '2003-01-01')
            .filter(i => i.dated_date <= ModelUtil.effectivePUCalcFYEndDateForClient(year, this.props.client).format('YYYY-MM-DD'));
    };

    skIssuesAnswersFromIssueData = (issueData, year) => {
        return issueData.map(i => {
            return SkFormUtil.formatValuesForSkForm({
                ...i.sk_calc,
                ...(i.sk_analyst_answers && i.sk_analyst_answers[year] ? i.sk_analyst_answers[year] : {})
            });
        });
    }

    downloadClientSkExcel = async year => {
        this.setState({ loadingSkForm: true });
        const issueData = await this.skIssueDataForYear(year);
        const issuesAnswers = this.skIssuesAnswersFromIssueData(issueData, year);
        const excel = SkFormUtil.fillSkFormExcel(issuesAnswers, issueData, year, this.props.client);

        const excelBuffer = await excel.xlsx.writeBuffer();
        saveAs(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${this.props.client.name} Schedule K ${year} Excel Export.xlsx`);
        
        this.setState({ loadingSkForm: false });
    };

    downloadClientSkForm = async year => {
        this.setState({ loadingSkForm: true });
        const issueData = await this.skIssueDataForYear(year);
        
        const formYear = year - (this.props.client.fy_end_month == 12 ? 0 : 1);

        for(let i = 0; i < issueData.length; i+=4) {
            const pdfDoc = await SkFormUtil.fillSkForm(
                formYear,
                this.skIssuesAnswersFromIssueData(issueData.slice(i, i + 4), year),
                this.props.client
            );
    
            const pdfBytes = await pdfDoc.save();
            
            saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), `${this.props.client.name} Schedule K ${year} Form ${(Math.ceil(i / 4) + 1).toFixed(0)}.pdf`);
        }

        this.setState({ loadingSkForm: false });
    }

    render() {
        if(!this.props.client) {
            return '';
        }
        
        moment.tz.setDefault('UTC');

        let importRows;
        if(this.props.imports) {
            importRows = this.props.imports.map((i) => {
                const dtStr = moment(i.created_at).tz(moment.tz.guess()).format('MM/DD/YY HH:mm');
                const importName = i.type + ' at ' + dtStr;
                
                return (
                    <tr key={i.id}>
                        <td className="pl-3">
                            {importName}
                        </td>
                        <td className="font-italic">
                            {
                                i.rolled_back
                                    ? "Rolled back at " + moment(i.updated_at).tz(moment.tz.guess()).format('MM/DD/YY HH:mm')
                                    : ''
                            }
                        </td>
                        <td>
                            <a onClick={() => { this.showImportDataModal(importName, i.input_data); }}>Show Input Data</a>
                        </td>
                        <td>
                            {
                                i.rolled_back
                                    ? <a onClick={() => { this.handleUnrollback(i.id, importName); }}>Reverse rollback</a>
                                    : <a onClick={() => { this.handleRollback(i.id, importName); }}>Rollback</a>
                            }
                        </td>
                    </tr>
                );
            });
        }

        let exceptionListings;
        if(this.props.clientDataExceptions) {
            exceptionListings = this.props.clientDataExceptions.map((exception, i) => {
                return <DataExceptionListing exception={exception} client={this.props.client} key={"exception" + i} />
            });
        }

        // const fy23Closed = DateFunctions.fyIsClosed(2023, this.props.client.fy_end_month, this.props.client.fy_end_day);
        const fy24Closed = DateFunctions.fyIsClosed(2024, this.props.client.fy_end_month, this.props.client.fy_end_day);

        return (
            <div>
                <TitleBar pageTitle="Client Dashboard" clientName={this.props.client.name} />
                <DataTableModal
                    title={this.state.showingImportName}
                    tableContent={this.state.showingImportData}
                    show={this.state.showingImportDataModal}
                    onHide={this.onHideImportDataModal} />
                <UseImporterModal
                    show={this.state.showingUseImporterModal}
                    onHide={this.onHideUseImporterModal} />
                <ProjectImporterModal
                    show={this.state.showingProjectImporterModal}
                    onHide={this.onHideProjectImporterModal} />
                <AssetImporterModal
                    show={this.state.showingAssetImporterModal}
                    onHide={this.onHideAssetImporterModal} />
                <SpaceImporterModal
                    show={this.state.showingSpaceImporterModal}
                    onHide={this.onHideSpaceImporterModal} />
                <div className="clear-title-bar">
                    <div className="row pt-4">
                        <div className="col-6">
                            <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase">
                                Reports
                            </div>
                            <ul className="list-group border-0">
                                {
                                    fy24Closed && (
                                        <li className="list-group-item rounded-0 border-left-0 border-right-0 d-flex justify-content-between">
                                            <div className="d-flex">
                                                <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2024) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2024 Schedule K Forms</button>
                                                &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2024) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2024 SK Excel Export</button>
                                            </div>
                                            {
                                                this.state.loadingSkForm ? <div className="pull-right">Loading...</div> : <Fragment />
                                            }
                                        </li>
                                    )
                                }
                                <li className="list-group-item rounded-0 border-left-0 border-right-0 d-flex justify-content-between">
                                    <div className="d-flex">
                                        <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2023) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2023 Schedule K Forms</button>
                                        &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2023) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2023 SK Excel Export</button>
                                    </div>
                                    {
                                        !fy24Closed && this.state.loadingSkForm ? <div className="pull-right">Loading...</div> : <Fragment />
                                    }
                                </li>
                                <li className="list-group-item rounded-0 border-left-0 border-right-0 d-flex justify-content-between">
                                    <div className="d-flex">
                                        <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2022) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2022 Schedule K Forms</button>
                                        &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2022) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2022 SK Excel Export</button>
                                    </div>
                                </li>
                                <li className="list-group-item rounded-0 border-left-0 border-right-0">
                                    <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2021) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2021 Schedule K Forms</button>
                                    &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2021) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2021 SK Excel Export</button>
                                </li>
                                <li className="list-group-item rounded-0 border-left-0 border-right-0">
                                    <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2020) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2020 Schedule K Forms</button>
                                    &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2020) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2020 SK Excel Export</button>
                                </li>
                                <li className="list-group-item rounded-0 border-left-0 border-right-0">
                                    <button className="btn btn-link p-0" onClick={() => { this.downloadClientSkForm(2019) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2019 Schedule K Forms</button>
                                    &nbsp;&nbsp;<button className="btn btn-link p-0" onClick={() => { this.downloadClientSkExcel(2019) }}><i className="icon icon-download"></i>&nbsp;&nbsp;2019 SK Excel Export</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-6">
                            <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg font-weight-bold text-muted text-uppercase">
                                Data Exceptions
                            </div>
                            <ul className="list-group border-0">
                                { exceptionListings }
                            </ul>
                        </div>
                        <div className="col-6">
                            <div className="pt-2 pb-2 pr-3 pl-3 bottom-solid-border card-bg text-muted d-flex justify-content-between">
                                <span className="font-weight-bold text-uppercase">Data Imports</span>
                                <span>
                                    <a
                                        onClick={this.showUseImporterModal}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Uses
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={this.showProjectImporterModal}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Projects
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={this.showAssetImporterModal}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Assets
                                    </a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a
                                        onClick={this.showSpaceImporterModal}>
                                        <span className="icon icon-upload"></span>&nbsp;
                                        Import Spaces
                                    </a>
                                </span>
                            </div>
                            <table className="table table-condensed w-100">
                                <tbody>
                                    { importRows }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientDashboard.propTypes = {
    client: PropTypes.object,
    match: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
    {
        client: state.client.client,
        clientDataExceptions: state.client.data_exceptions,
        clientIsLoading: state.client.clientIsLoading,
        clientHasErrored: state.client.clientHasErrored,
        clientErrorMessage: state.client.clientErrorMessage,
        imports: state.data_imports.list,
        importsListIsLoading: state.data_imports.listIsLoading,
        importsListHasErrored: state.data_imports.listHasErrored,
        importsListErrorMessage: state.data_imports.listErrorMessage,
    }
);

const mapDispatchToProps = dispatch => {
    return {
        loadClientAndDependencies: (clientId) => CommonStateFunctions.loadClientAndDependencies(dispatch, clientId),
        loadDataImportList: (clientId, onLoad) => dispatch(DataImportActionCreators.loadDataImports(clientId, onLoad)),
        rollbackImport: (importId, rollbackData) => dispatch(DataImportActionCreators.rollback(importId, rollbackData)),
        unrollbackImport: (importId, rollbackData) => dispatch(DataImportActionCreators.unrollback(importId, rollbackData)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDashboard);